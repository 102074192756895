import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import MultiVideoPlayer from '../../../../components/MultiVideoPlayer';
import Icon from '../../../../components/Icon';

const FinalistPresentations2017 = () => (
  <Layout
    title="3M 2017 YSC Finalist Presentations"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">
            3M 2017 YSC Finalist Presentations
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M 2017 YSC Finalist Presentations</h1>
            {/* Video Container */}
            <div className="video-container__with-pager multivideo">
              <Link
                to="../3M-2018-YSC-Finalist-Presentations"
                className="previous"
              >
                <Icon name="arrowleftgray" />
              </Link>
              <MultiVideoPlayer
                videos={[
                  {
                    guidSrc: 'a9946589-cd73-4e50-a110-2d56f0bc194c',
                    poster: '2017-finalist-thumbnail-gitanjali-rao.jpg',
                    title: '3M 2017 YSC Finalist Presentation: Gitanjali Rao',
                    description:
                      'View 2017 Challenge Winner Gitanjali Rao’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'ab873da6-d608-4f29-bdbf-601ee2c700ee',
                    poster: '2017-finalist-thumbnail-allie-weber.jpg',
                    title: '3M 2017 YSC Finalist Presentation: Allie Weber',
                    description:
                      'View 2017 Challenge Winner Allie Weber’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'b18b950b-7cfd-4851-9d04-ce237497fe84',
                    poster: '2017-finalist-thumbnail-rithvik-ganesh.jpg',
                    title: '3M 2017 YSC Finalist Presentation: Rithvik Ganesh',
                    description:
                      'View 2017 Challenge Winner Rithvik Ganesh’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '20a95fa1-47de-4775-bdf6-7dba9a46f616',
                    poster: '2017-finalist-thumbnail-kathryn-lampo.jpg',
                    title: '3M 2017 YSC Finalist Presentation: Kathryn Lampo',
                    description:
                      'View 2017 Challenge Winner Kathryn Lampo’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '37d11867-1298-464b-ad3e-04e6069cab44',
                    poster: '2017-finalist-thumbnail-anika-bhagavatula.jpg',
                    title:
                      '3M 2017 YSC Finalist Presentation: Anika Bhagavatula',
                    description:
                      'View 2017 Challenge Winner Anika Bhagavatula’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: '29a41639-2682-481a-b99c-fb4f9614ac8d',
                    poster: '2017-finalist-thumbnail-samu-shrestha.jpg',
                    title: '3M 2017 YSC Finalist Presentation: Samu Shrestha',
                    description:
                      'View 2017 Challenge Winner Samu Shrestha’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'f8f63e01-0b05-4c94-9feb-b365942e0106',
                    poster: '2017-finalist-thumbnail-devin-willis.jpg',
                    title: '3M 2017 YSC Finalist Presentation: Devin Willis',
                    description:
                      'View 2017 Challenge Winner Devin Willis’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'e76bd38e-fa10-4f7e-8f8b-8e485c8cc824',
                    poster: '2017-finalist-thumbnail-austin-crouchley.jpg',
                    title:
                      '3M 2017 YSC Finalist Presentation: Austin Crouchley',
                    description:
                      'View 2017 Challenge Winner Austin Crouchley’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'd9e2e1b6-705e-425f-8ee7-2913bdea5c59',
                    poster: '2017-finalist-thumbnail-simone-jacobs.jpg',
                    title: '3M 2017 YSC Finalist Presentation: Simone Jacobs',
                    description:
                      'View 2017 Challenge Winner Simone Jacobs’s Presentation.',
                    className: 'de-vid',
                  },
                  {
                    guidSrc: 'e779b551-0738-4280-9bb3-716604ba1353',
                    poster: '2017-finalist-thumbnail-laalitya-acharya.jpg',
                    title:
                      '3M 2017 YSC Finalist Presentation: Laalitya Acharya',
                    description:
                      'View 2017 Challenge Winner Laalitya Acharya’s Presentation.',
                    className: 'de-vid',
                  },
                ]}
              />
              <Link to="../3M-2016-YSC-Finalist-Presentations" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <p className="text-26">
              Hear from the <strong>2017 Top Young Scientist Finalists</strong>{' '}
              as each presents their entry idea for the Annual Young Scientist
              Challenge.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FinalistPresentations2017;
